import React, { useEffect, useRef, useState } from 'react';
import imgTree from '../p/webp/tree.webp'
import imgUfo from '../p/webp/ufo.webp'
import imgRoots from '../p/webp/roots.webp'
import imgBook from '../p/webp/book.webp'
import imgRascal from '../p/webp/rascal.webp'
import imgDoor from '../p/webp/door.webp'
import imgRing from '../p/webp/ring.webp'
import imgDino from '../p/webp/dino.webp'
import imgStar from '../p/webp/star.webp'
import imgShoot from '../p/webp/shoot.webp'
import imgAcorn from '../p/webp/acorn.webp'
import imgTickadoc from '../p/webp/tickadoc.webp'
import imgGradient from '../p/webp/gradient.webp'
import imgTreetop from '../p/webp/treetop.webp'
import imgPoem from '../p/webp/poem.webp'
import imgBlack from '../p/webp/black.webp'
import imgMushroom from '../p/webp/mushroom.webp'
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';

function url(title) {
    return title.replace(/ /g,'_').replace(/\?/g,'')
}

function p() {
    return localStorage.getItem('daddy')?localStorage.getItem('daddy') : null;
}

let imgLoaded = {};

export default function Tree({ fruit, setFruit, setStory, story, tab, route, counts, password }) {
    let [blocks, setBlocks] = useState([]);
    let blockRefs = useRef({});
    let [scroll, setScroll] = useState(-1);
    let windowRef = useRef({});
    let [onMobile, setOnMobile] = useState(false);
    let [book, setBook] = useState(false);
    let [loaded, setLoaded] = useState(false);
    let doneScrolling = useRef(false);
    let navigate = useNavigate();
    let [newsLetterModal, setNewsLetterModal] = useState();
    let [confirmNewsLetterModal, setConfirmNewsLetterModal] = useState();

    async function refresh() {
        let { data } = await axios.post('https://kaisboatfund.co.uk/api/p/titles',{p:p()});
        setFruit(data.fruit);
        setStory(data.story);
    }

    useEffect(() => {
        setOnMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent));
    }, [])
    
    useEffect(() => {
        let book = [...fruit,...story].find(b => url(b[0]) == route);
        setBook(book?book:false);
    }, [route, fruit, story])
    
    let [welcome, setWelcome] = useState(false);
    useEffect(() => {
        if (route == 'welcome') {
            setTimeout(() => {
                setWelcome(true);                
            },1)
        }
    },[route])

    useEffect(() => {
        let blocks = [];
        for (let i = -1; i < fruit.length; i += 4){
            let block = {
                books: [],
                flipped: blocks.length % 2 == 1,
                className: "tree"
            }
            for (let j = i + 3; j >= i; j--) {
                block.books.push(j < fruit.length && j >= 0 ? {
                    title: fruit[j][0],html: fruit[j][1],
                    n: j,
                    category: 'fruit',
                    flipped:j % 2 == blocks.length % 2
                } : null)
            }
            blocks.unshift(block);
        }
        blocks[blocks.length - 1].rascal = {
            title: story[3][0],html: story[3][1], n: 3, category: 'story'
        }
        blocks[blocks.length - 1].door = {
            n: -1
        }
        blocks.push({
            className: "roots",
            ring: { title: story[5][0],html: story[5][1], n: 5, category: 'story' },
            mushroom: {title: story[4][0],html: story[4][1], n: 4, category: 'story'}
        })
        blocks.push({
            className: "dinosaur",
        })
        blocks.push({
            className: "poem",
        })
        blocks.push({
            className: "shoot",
            acorn: {title: story[6][0],html: story[6][1],n:6,category:'story'}
        })
        blocks.unshift({
            className: "treetop",
            flipped: !blocks[0].flipped
        })
        blocks.unshift({
            className: "gradient",
            bird: { title: story[2][0],html: story[2][1], n: 2, category: 'story' },
            ufo:{title: story[1][0],html: story[1][1],n:1,category:'story'}
        })
        blocks.unshift({
            className: "black",
            star:{title: story[0][0],html: story[0][1],n:0,category:'story'}
        })
        blocks.unshift({ className: "black" })
        blocks.push({ className: "black" })
        blocks.unshift({ className: "black" })
        blocks.push({ className: "black" })
        setBlocks(blocks)
        blockLength.current = blocks.length;
    }, [fruit])

    useEffect(() => {
        if (blocks.length > 0) {
            if (blocks.reduce((a,b)=>a&&b.loaded,true)) {
                setScroll(0);
                getJumpDistance();
                setLoaded(true);
            }
        }
    }, [blocks])

    let jumpDistance = useRef(0);
    let jumpMargin = useRef(0);
    let lastScroll = useRef(0);
    let blockLength = useRef(0);

    function getJumpDistance() {
        if (blockRefs.current[0]) {
            let h = blockRefs.current[0].getBoundingClientRect().height;
            jumpDistance.current = h * (blockLength.current - 2);
            jumpMargin.current = h;
        }
    }

    let scrollTimeout = useRef();
    let [eventScroll, setEventScroll] = useState();

    useEffect(() => {
        window.addEventListener('resize', getJumpDistance);
        scrollTimeout.current = setInterval(() => {
            if (jumpMargin.current > 0) {
                let scroll = windowRef.current.scrollTop;
                if (scroll != lastScroll.current) setEventScroll(scroll);
                if (scroll > lastScroll.current) {
                    if (scroll > jumpDistance.current) {
                        windowRef.current.scrollTop -= (jumpDistance.current-jumpMargin.current);
                        lastScroll.current = windowRef.current.scrollTop;
                    } else lastScroll.current = scroll;
                } else if (scroll < lastScroll.current) {
                    if (scroll < jumpMargin.current) {
                        windowRef.current.scrollTop += (jumpDistance.current - jumpMargin.current);
                        lastScroll.current = windowRef.current.scrollTop;
                    } else lastScroll.current = scroll;
                }
            }
        },200)
        return () => {
            window.removeEventListener('resize', getJumpDistance);
            clearTimeout(scrollTimeout.current);
        }
    },[])

    let maxScroll = useRef(false);
    let maxTime = 150;

    let timeoutRef = useRef();

    useEffect(() => {
        if (scroll >= 0) {
            setTimeout(() => {
                let lastTree = blockRefs.current[blocks.length - 7];
                let rect0 = lastTree.getBoundingClientRect();
                let top0 = rect0.top;
                let top1 = windowRef.current.getBoundingClientRect().top;
                let d = top0 - top1;
                if (scroll < maxTime) {
                    if (!maxScroll.current) maxScroll.current = d;
                    scroll++
                    windowRef.current.scrollTop = scroll
                    let sin = ((Math.cos((scroll / maxTime) * Math.PI) + 1) / 2);
                    sin = 1 - (sin * sin*sin);
                    windowRef.current.scrollTop = sin * maxScroll.current + rect0.height/6;
                    timeoutRef.current = setTimeout(() => {
                        setScroll(scroll);
                    },1000/60)
                } else {
                    doneScrolling.current = true;
                }
            })
        }
    }, [scroll])
    
    useEffect(() => {
        let f = () => {
            if (!doneScrolling.current) {
                clearTimeout(timeoutRef.current);
                setScroll(-1)
                doneScrolling.current = true;
            }
        }
        document.addEventListener('wheel', f);
        return ()=>document.removeEventListener('wheel', f);
    },[])
    
    let treeWindow = useRef();

    return <>
        <div className="Window">
            <div ref={treeWindow} className={`TreeWindow W ${tab=='Tree' ? '' : 'invisible'} ${!loaded?'black':''}`} onTouchStart={() => {
                if (!doneScrolling.current) {
                    clearTimeout(timeoutRef.current);
                    setScroll(-1)
                    doneScrolling.current = true;
                }
            }}>
                <div
                    className="innerTreeWindow"
                    ref={windowRef}
                >
                    {blocks.map(({ flipped, loaded, books, className, rascal, ring, bird, ufo, star, acorn, mushroom, door }, i) => className == "gradient" || className == "black" || className == "roots" || className=="dinosaur" || className=="poem"|| className=="shoot" ? <div
                        className={"block"}
                        ref={r=>blockRefs.current[i] = r}
                        key={i}
                        style={{opacity:imgLoaded[className]?1:0}}
                    >
                        {mushroom ? <NavLink to={'/'+encodeURIComponent(url(mushroom.title))}><img alt={""} className="mushroom" src={imgMushroom}/></NavLink> : null}
                        <img alt={""} className={"square "+className} src={
                                className == "gradient" ? imgGradient : className == "black" ? imgBlack :
                                className == "roots" ? imgRoots :
                                className == "dinosaur" ? imgDino :
                                className == "poem" ? imgPoem : 
                                className == "shoot" ? imgShoot: null
                            }
                            onLoad={() => {
                                imgLoaded[className] = true;
                                blocks[i].loaded = true;
                                setBlocks(blocks.slice())
                            }}
                        />
                        {bird ? <NavLink to={'/'+encodeURIComponent(url(bird.title))}><img alt={""} className="bird" src={imgTickadoc}/></NavLink> : null}
                        {ufo ? <NavLink to={'/'+encodeURIComponent(url(ufo.title))}><img alt={""} className="ufo" src={imgUfo}/></NavLink> : null}
                        {star ? <NavLink to={'/'+encodeURIComponent(url(star.title))}><img alt={""} className="star" src={imgStar}/></NavLink> : null}
                        {acorn ? <NavLink to={'/'+encodeURIComponent(url(acorn.title))}><img alt={""} className="acorn" src={imgAcorn}/></NavLink> : null}
                        {ring? <NavLink to={'/'+encodeURIComponent(url(ring.title))}><img alt={""} className="ring" src={imgRing}/></NavLink> : null}
                    </div>
                    : className == "treetop" ? <div
                        className={"block"}
                        ref={r=>blockRefs.current[i] = r}
                        key={i}
                        style={{opacity:imgLoaded[className]?1:0}}
                    >
                        <img alt={""} className={"treetop "+(flipped?'flipped ':'')} src={imgTreetop} onLoad={() => {
                                imgLoaded[className] = true;
                                blocks[i].loaded = true;
                                setBlocks(blocks.slice())
                            }}
                        />
                    </div>
                    : className == "tree" ? <div
                        className={"block "+(flipped?'flipped ':'')}
                        ref={r=>blockRefs.current[i] = r}
                        key={i}
                        style={{opacity:imgLoaded[className]?1:0}}
                    >
                        {rascal ? <NavLink to={'/'+encodeURIComponent(url(rascal.title))}><img alt={""} className="rascal" src={imgRascal}/></NavLink> : null}
                        {door ? <NavLink to={'/'+encodeURIComponent('welcome')}><img alt={""} className="door" src={imgDoor}/></NavLink> : null}
                        <img alt={""} className="tree" src={imgTree} onLoad={() => {
                                imgLoaded[className] = true;
                                blocks[i].loaded = true;
                                setBlocks(blocks.slice())
                            }}
                        />
                        {books.map((b, j) => b ? <Fruit key={j} b={b} j={j} flipped={b.flipped} onMobile={onMobile} scroll={eventScroll} window={treeWindow}/> : null)}
                    </div> : null)}
                    {!loaded ? <div className="loading">Loading...</div>:null}
                </div>
            </div>
            <div className={`BioWindow W ${tab=='Biography' ? '' : 'invisible'}`}>
                <div className="innerBio">
                    <p> I hope you enjoy reading my little selection of stories and poems, as much as I have writing them.  I can’t wait to read my next one.</p>
                    <p>If you want to keep updated with my antics, you may sign up to my newsletter in the ‘Contact’ tab below.</p>
                    <p className="mb">I haven’t always written such things, but after leaving school I discovered that letter writing was a pleasure – although I pity those who had to decipher my hand writing, and interpret my imaginative spelling.</p>

                    <p className="mb">I didn’t do so well at school; just scraping through with English and Maths in my CSEs. Most recently though, I graduated from the Open University with an upper second class Honours Degree in Creative Writing and Religious Studies.  I have always been able to put the letters BA after my name, but until now that has stood for Bugger All.</p>

                    <p>I live with my wife and two artists in our North East London, 1950s semi, at the unholy confluence of the M11 motorway and the River Roding.</p>
                    <p>I love nature over materialism, and I’d rather a frosty morning in Essex than a day on the beach in the Med.</p>
                    <p>When I’m not writing or stacking the shelves in Waitrose I make bread, and when I’m not building something in my garden I like walking through the local landscape.</p>
                    <p className="mb">Strangely enough; although I go on Facebook, I never post pictures of cute kittens.</p>
    
                    <p>I have had work published in:</p>
                    <ul>
                        <li>2020 Together: an anthology of shorts</li>
                        <li>2021 still Together: an anthology of shorts</li>
                        <li>Where's the Manual? And Other Thoughts on Parenthood</li>
                        <li>The Spring 2022 edition of Makarelle - Landmarks.</li>
                    </ul>
                </div>
            </div>
            <div className={`ContactWindow W ${tab == 'Contact' ? '' : 'invisible'}`}>
                <div className="leftAlign">
                    Sign up to my newsletter to keep up with my latest stories - I promise not to spam you with emails or sell your information to the Vogons.
                    <div className="signup"><button onClick={()=>setNewsLetterModal(true)}>Click here to sign up</button></div>
                </div>
                <div>
                    Email: <a href="mailto:peter@pdouglashammond.co.uk">peter@pdouglashammond.co.uk</a>
                </div>
                <div>
                    Facebook: <a href="https://www.facebook.com/pdouglas.hammond">@pdouglas.hammond</a>
                </div>
            </div>
            <div className={`AnalyticsWindow W ${tab=='Analytics' ? '' : 'invisible'}`}>
                {counts?<Counts counts={counts} />:null}
            </div>
            <div className={`NewsletterWindow W ${tab=='Newsletter' ? '' : 'invisible'}`}>
                {counts?<Newsletter password={password}/>:null}
            </div>
            <div className={`EditWindow W ${tab=='Edit' ? '' : 'invisible'}`}>
                {counts ? <Edit fruit={fruit} story={story} password={password} refresh={refresh} />:null}
            </div>
        </div>
        <Book b={book} showCount={!!counts} />
        {route == 'welcome' ? <div className={`modal ${welcome?'visible':''}`}>
            <div className="modalWindow modalOuter">
            <div className="titleBar"><div className="X" onClick={() => {
                    setWelcome(false);
                    setTimeout(() => navigate('/'),1000)
                }}>X</div></div>
                <div className="modalInner">
                <h1>Welcome!</h1>
                <p>Welcome to my little website.  Feel free to burrow in to the soil, climb the tree, or fly in the skies above.  Here you will find a selection of my writing: some short, some longer.  There are poems and fiction.  There are pieces inspired by love, and life, and ageing, and the world around.  In my writing you will glimpse something of the Divine, the mysteries of our world, the fiction of science, and the fascination of the “Ooherr”.</p>
                <p>Here’s a question for you: Do you know where Stonehenge can be found?  What if you were wrong, and when you past it by one day it wasn’t there – what would you do?</p>
                <p>Here’s another: if the world was about to end and it was all you fault; if you had the chance to go back in time and put everything right, which moment would you change?</p>
                <p>And what about a Tikadoc: would you plant one in your garden? or eat it? or put it on your mantel next to your collection of 1950s match boxes and Pokémon cards?</p>
                <p>Finally: who was it who said A journey of a thousand miles begins with a single step? Was it (A) Chairman Mao, or (B) Gandhi?</p>
                <p>Happy reading,</p>
                <p><i>P Douglas Hammond</i></p>

            </div></div>
        </div> : null}
        {newsLetterModal ? <NewsletterModal close={()=>setNewsLetterModal(false)} callback={async ({name, email}) => {
            let { data } = await axios.post('https://kaisboatfund.co.uk/api/p/newsletter', { name, email });
            setConfirmNewsLetterModal(true);
        }} /> : null}
        {confirmNewsLetterModal ? <Modal className="newsletterModal" body="Thank you for signing up! You will not be disappointed." close={() => setConfirmNewsLetterModal(false)} buttons={1} />:null}
    </>
}

function Counts({ counts }) {
    let [bars, setBars] = useState([]);
    let [graph, setGraph] = useState([]);
    let [hover, setHover] = useState(-1);
    let [mode, setMode] = useState('year');
    useEffect(() => {
        let countries = counts.countries;
        while (countries.length > 12) countries.pop();
        let max = Math.max(...countries.map(c => c.count));
        let bars = countries.map(c => ({ ...c, height: c.count / max }));
        setBars(bars)
    }, [])
    useEffect(() => {
        let months = ['January','February','March','April','May','June','July','August','September','October','November','December',];
        let exts = ['th','st','nd','rd','th','th','th','th','th','th','th'];

        if (counts.countHistory.length > 1) {
            let today = Math.floor(new Date().getTime() / (1000 * 60 * 60 * 24));
            let lastYear = today - (mode=='month'?30:mode=='week'?7:365) + 1;
            let graph = [];
            let max = counts.countHistory[0].count;
            for (let day = lastYear-1; day <= today; day++) {
                let data = counts.countHistory.filter(c => c.day <= day).sort((a, b) => b - a);
                data = data.length > 0 ? data[0] : { day, count: 0 };
                let last = graph.length == 0 ? 0 : graph[graph.length - 1].count;

                let date = new Date(day * (1000 * 60 * 60 * 24));
                let d = date.getDate();
                let m = date.getMonth();

                graph.push({
                    day: `${d}${exts[d%10]} ${months[m]}`,
                    count: data.count,
                    height: data.count / max,
                    increment: data.count - last
                });
            }
            graph.shift();
            setGraph(graph);
        }
    },[mode])
    return <div className="Count">
            <div className="count">
                Total hits: <span>{counts.totalCount}</span>
            </div>
            <div className="count">
                Unique hits: <span>{counts.uniqueCount}</span>
            </div>
            <div className="countries">{bars.map( ({country,count,height})=>
                <div className="country" key={country}>
                    <div className="barContainer" style={{ height: 50 }}>
                        <div className="bar" style={{ height: 50 * height}} />
                    </div>
                    <div className="countryCode">{country}</div>
                    <div className="countryNumber">{count}</div>
                </div>
            )}</div>
            <div className={`graph ${mode}`} onMouseLeave={() => setHover(-1)}>
                {graph.map(({ height },i) => <div
                    className={`datum ${hover == i ? 'hovered' : ''}`}
                    key={i}
                    onMouseEnter={() => {
                        setHover(i)
                    }}
                >
                    <div style={{height:height*100}} />
                </div>)}
            </div>
            <div className="pop">
                {hover >= 0 ? <>
                    <div>{graph[hover].day} - {graph[hover].count} views (+{graph[hover].increment})</div>
                </>:null}
            </div> 
            <p className="graphMode">View <span className={mode == 'year'?'red':''} onClick={()=>setMode('year')}>year</span>, <span className={mode == 'month'?'red':''} onClick={()=>setMode('month')}>month</span>, <span className={mode == 'week'?'red':''} onClick={()=>setMode('week')}>week</span></p>
    </div>
}

function Edit({ fruit, story, password, refresh }) {
    let [selected, setSelected] = useState();
    let [editList, setEditList] = useState();
    let [addStoryTitle, setAddStoryTitle] = useState();
    let [addStory, setAddStory] = useState();
    let [titleFound, setTitleFound] = useState();
    if (selected) return <EditStory title={selected[0]} story={selected[1]} close={() => setSelected()} password={password} refresh={refresh} />
    if (addStory) return <EditStory title={addStory} story={'<pre>Once upon a time there was a <b>frog.</b>\n<i>The end.</i></pre>'} close={() => setAddStory()} password={password} refresh={refresh} />
    if (editList) return <EditList stories={fruit} password={password} refresh={refresh} close={() => setEditList(false)} />
    return <div className="Edit">
        {addStoryTitle ? <InputModal body={`What is the title of your new story? (Triple check this because it can not be changed!)`} placeholder="Put the title here" callback={async title => {
            let { data } = await axios.post('https://kaisboatfund.co.uk/api/p/check-title', { title });
            if (data.found) {
                setTitleFound(true);
            } else {
                setAddStory(title);
            }
        }} close={() => setAddStoryTitle(false)} /> : null}
        {titleFound ? <Modal error body="You already have a story with that title!" buttons={1} close={()=>setTitleFound(false)} />:null}
        <h2>Books</h2>
        <div className="bookList">
            <div className="buttons"><button onClick={()=>setEditList(true)}>Edit list</button><button onClick={()=>setAddStoryTitle(true)}>Add story</button></div>
            {fruit.map((b,i) => <div className="bookTitle" key={`${b[0]}_${i}`} onClick={()=>setSelected(b)}>
                {b[0]}
            </div>)}
        </div>
        <h2>Easter eggs</h2>
        <div className="bookList">
            {story.map((b,i) => <div className="bookTitle" key={`${b[0]}_${i}`} onClick={()=>setSelected(b)}>
                {b[0]}
            </div>)}
        </div>
    </div>
}

function useGetState(v) {
    let [state, setState] = useState(v);
    let loaded = useRef(false);
    let ref = useRef();
    useEffect(() => {
        ref.current = state;
        loaded.current = true;
    }, [state]);
    function getState() {
        if (loaded.current) return ref.current;
        return v;
    }
    return [state, setState, getState];
}

function EditList({ stories, password, refresh, close }) {
    let [list, setList, getList] = useGetState(stories.map(s => s[0]).reverse());
    let [order, setOrder, getOrder] = useGetState(stories.map((s, i) => i));
    let [drag, setDrag] = useState(-1);
    let [my, setMy] = useState(0);
    let shuffleBox = useRef();
    let [selected, setSelected] = useState(-1);
    let [deleteModal, setDeleteModal] = useState(false);

    function bake() {
        let order = getOrder();
        let list0 = getList();
        let list = order.map((o,i) => [o, list0[i]]);
        list = list.sort((a, b) => a[0] - b[0]);
        list = list.map(l => l[1]);
        setList(list);
        setOrder(list.map((s, i) => i));

        save(list);
    }

    async function save(list) {
        await axios.post('https://kaisboatfund.co.uk/api/p/edit-list', { list:list.slice().reverse(), password });
        await refresh();
    }

    useEffect(() => {
        if (drag >= 0) {
            let mouseup = () => {
                setDrag(-1)
                setTimeout(bake, 250);
            }
            document.addEventListener('mouseup', mouseup);
            let mousemove = e => {
                let my = Math.floor(e.pageY - shuffleBox.current.getBoundingClientRect().top);
                let y = Math.floor(my / p);
                y = y < 0 ? 0 : y > list.length - 1 ? list.length - 1 : y;
                let o = list.map((s, i) => i);
                o = o.filter(o => o != drag);
                o.splice(y, 0, drag);
                let order = list.map((s, i) => i);
                o.forEach((o, i) => order[o] = i);
                setOrder(order);
            }
            document.addEventListener('mousemove', mousemove);
            return () => {
                document.removeEventListener('mouseup', mouseup);
                document.removeEventListener('mousemove', mousemove);
            }
        }

    }, [drag])

    let p = 16 * 2;

    return <div className="Edit editList">
        <div className="X" onClick={close}>X</div>
        {deleteModal ? <Modal body={`Are you sure you want to delete ‘${selected}’?`} callback={async () => {
            let i = list.indexOf(selected);
            if (i >= 0) {
                list.splice(i, 1);
                order.splice(i, 1);
                order = order.map(o => o > i ? o - 1 : o);
                setList(list.slice());
                setOrder(order.slice());
                await save(list);
            }
        }} close={() => setDeleteModal(false)} /> : null}
        <div className="centreAlign">
            <h2>Tree top</h2>
        </div>
        <div className="shuffleBox" ref={shuffleBox} style={{ height: list.length * p }} onMouseMove={e => {
            let my = Math.floor(e.pageY-shuffleBox.current.getBoundingClientRect().top);
            setMy(my)
        }}>
            {list.map((t, i) => <div
                className={`shuffleItem ${drag==i?'dragging':''} ${selected==t?'selected':''}`}
                key={`${t}_${i}`}
                style={{ top: drag==i?my-(p/2):order[i]*p}}
            >
                <div className="item" >
                    <div className="itemTitle" onMouseDown={() => {
                        setDrag(i);
                        setSelected(t);
                    }}>{t}</div>
                    {selected == t && drag < 0 ? <button onClick={() => setDeleteModal(true)}>Delete</button>:null}
                </div>
            </div>)}
        </div>
        <div className="centreAlign">
            <h2>Tree roots</h2>
        </div>
    </div>
}

function Newsletter({ password }) {
    let [emails, setEmails] = useState();
    let [areYouSure, setAreYouSure] = useState();
    useEffect(() => {
        (async () => {
            let { data } = await axios.post('https://kaisboatfund.co.uk/api/p/newsletter-emails', { password });
            setEmails(data);
        })()
    }, [])
    if (!emails) return null;
    if (emails.length == 0) return <div className="Newsletter"><h2>There are no subscribers to your newsletter.</h2></div>
    return <div className="Newsletter">
        {areYouSure ? <Modal body="Are you sure you want to remove this email?" callback={async () => {
            await axios.post('https://kaisboatfund.co.uk/api/p/remove-email', { password, email:areYouSure });
            emails = emails.filter(e => e[1] != areYouSure);
            setEmails(emails.slice());
        }} close={()=>setAreYouSure(false)} />:null}
        <h2>Emails</h2>
        <div>To send out a bulk email, copy this text and paste it into the 'BCC' box:</div>
        <div className="copyTheseEmailAddresses">{emails.map(e=>e[1]).join(', ')}</div>
        <div>If you wish to remove an email from the list, you can do that here:</div>
        <div className="emailList">
            {emails.map(e => <div className="emailItem" key={e[1]}><div className="emailBit">{e.join(": ")}</div><button onClick={()=>setAreYouSure(e[1])}>Remove</button></div>)}
        </div>
    </div>
}

function InputModal({ body, callback, close, placeholder }) {
    let [visible, setVisible] = useState(false);
    let [loading, setLoading] = useState(false);
    let [string, setString] = useState('');
    useEffect(() => {
        setTimeout(() => setVisible(true), 1);
    }, [])
    function CLOSE() { 
        setVisible(false);
        setTimeout(close,250)
    }

    async function submit() {
        setLoading(true);
        await callback(string.trim());
        setLoading(false);
        CLOSE();
    }

    return <div className={`modal ${visible?'visible':''}`}>
    <div className="modalWindow modalOuter">
        <div className="titleBar"><div className="X" onClick={CLOSE}>X</div></div>
            <div className="modalInner">
                {body}
                <div className="inputBox">
                    <input
                        placeholder={placeholder}
                        type="text"
                        value={string}
                        onChange={e => setString(e.target.value)}
                        onKeyDown={e => {
                            if (!loading && string.trim() && e.key == 'Enter') {
                                submit();
                            }
                        }}
                        disabled={loading}
                    />
                </div>
                <div className="buttons">
                    <button disabled={loading || !string.trim()} onClick={submit}>Submit</button>
                    <button disabled={loading || !string.trim()} onClick={CLOSE}>Cancel</button>
                </div>
            </div>
        </div>
    </div>
}

function NewsletterModal({ callback, close }) {
    let [visible, setVisible] = useState(false);
    let [loading, setLoading] = useState(false);
    let [name, setName] = useState('');
    let [email, setEmail] = useState('');
    
    useEffect(() => {
        setTimeout(() => setVisible(true), 1);
    }, [])
    function CLOSE() { 
        setVisible(false);
        setTimeout(close,1000)
    }

    async function submit() {
        setLoading(true);
        await callback({name:name.trim(), email:email.trim()});
        setLoading(false);
        CLOSE();
    }

    return <div className={`modal newsletterModal ${visible?'visible':''}`}>
    <div className="modalWindow modalOuter">
        <div className="titleBar"><div className="X" onClick={CLOSE}>X</div></div>
            <div className="modalInner">
                Please give your name and email address:
                <div className="inputBox">
                    <label>
                        Name:
                        <input
                            placeholder="Name"
                            type="text"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            onKeyDown={e => {
                                if (!loading && name.trim() && email.trim() && e.key == 'Enter') {
                                    submit();
                                }
                            }}
                            disabled={loading}
                        />
                    </label>
                    <label>
                        Email:
                        <input
                            placeholder="Email"
                            type="text"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            onKeyDown={e => {
                                if (!loading && email.trim() && email.trim() && e.key == 'Enter') {
                                    submit();
                                }
                            }}
                            disabled={loading}
                        />
                    </label>
                </div>
                <div className="buttons">
                    <button disabled={loading || !name.trim() || !email.trim()} onClick={submit}>Submit</button>
                    <button disabled={loading || !name.trim() || !email.trim()} onClick={CLOSE}>Cancel</button>
                </div>
            </div>
        </div>
    </div>
}

function Modal({ body, callback, close, buttons=2, error, className='' }) {
    let [visible, setVisible] = useState(false);
    let [loading, setLoading] = useState(false);
    useEffect(() => {
        setTimeout(() => setVisible(true), 1);
    }, [])
    function CLOSE() { 
        setVisible(false);
        setTimeout(close,className ? 1000 : 250)
    }
    return <div className={`modal ${className} ${visible?'visible':''} ${error?'errorModal':null}`}>
    <div className="modalWindow modalOuter">
        <div className="titleBar"><div className="X" onClick={CLOSE}>X</div></div>
            <div className="modalInner">
                {body}
                <div className="buttons">
                    {buttons==2?<button disabled={loading} onClick={async () => {
                        setLoading(true);
                        await callback();
                        setLoading(false);
                        CLOSE();
                    }}>Yes</button>:null}
                    <button disabled={loading} onClick={CLOSE}>{buttons==1?"OK":"No"}</button>
                </div>
            </div>
        </div>
    </div>
}

let maxUndo = 50;

function EditStory({ title, story: story0, close, password, refresh }) {
    let [story, setStory] = useState(story0.trim().substring(5, story0.length - 6).replace(/\r/g, ''))
    // let [story, setStory] = useState('')
    let [height, setHeight] = useState(0);
    let ref = useRef();
    let textarea = useRef();
    let [closeModal, setCloseModal] = useState(false);
    let [saveModal, setSaveModal] = useState(false);
    let [validateModal, setValidateModal] = useState(false);
    let [history, setHistory] = useState([]);
    let [unhistory, setUnhistory] = useState([]);

    let blockH = useRef(false);
    let speedy = useRef(false);
    let timeout = useRef();

    useEffect(() => {
        function resize() {
            setHeight(ref.current.getBoundingClientRect().height)
        }
        resize();
        window.addEventListener('resize', resize);


        let addHistory = () => {
            history.push(story);
            while (history.length > maxUndo) history.shift();
            setHistory(history.slice());
        }
        if (blockH.current || speedy.current) {
            clearTimeout(timeout.current);
            addHistory();
        } else {
            clearTimeout(timeout.current);
            timeout.current = setTimeout(addHistory,500);
        }


        if (!blockH.current) {
            setUnhistory([]);
        }
        blockH.current = false;
        speedy.current = false;

        return () => window.removeEventListener('resize', resize);
    }, [story])


    function getSel() {
        let txtarea = textarea.current;
        let start = txtarea.selectionStart;
        let finish = txtarea.selectionEnd;
        return [start, finish]
    }
    function insert(story, string, i) {
        return story.substring(0, i) + string + story.substring(i, story.length);
    }

    function validate() {
        let split = story.split('<');
        split = split.map(s => s.split('>'));
        split.forEach(s => {
            if (s[0].indexOf('/') > 0) {
                s[0] = [''];
            } else {
                s[0] = s[0].split(' ')[0]
            }
        })
        let stack = [];
        for (let s of split) {
            if (s.length > 1 && s[0] != '') {
                if (s[0].substring(0, 1) != '/') {
                    stack.push('/' + s[0])
                } else {
                    if (stack.length > 0) {
                        if (stack.pop() != s[0]) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
            }
        }
        if (stack.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    async function save() {
        await axios.post('https://kaisboatfund.co.uk/api/p/edit', { title, story, password });
        await refresh();
    }

    return <div className="Edit editStory">
        {closeModal?<Modal body="Are you sure you want to close?" callback={close} close={()=>setCloseModal(false)}/>:null}
        {saveModal ? <Modal body="Are you sure you want to save? This will update the story on the website!" callback={save} close={() => setSaveModal(false)} /> : null}
        {validateModal ? <Modal error body="There is an error in your code! Perhaps one of your <i> tags is missing its </i> counterpart?" buttons={1} close={()=>setValidateModal(false)} />:null}
        <div className="tools">
            <div className="buttonGroup">
                <button onClick={() => { // undo
                    if (history.length > 1) {
                        unhistory.push(history.pop());
                        setUnhistory(unhistory.slice());
                        blockH.current = true;
                        setStory(history.pop());
                    }
                    setHistory(history.slice());
                }} disabled={history.length <= 1}><svg x="0px" y="0px" viewBox="0 0 24 24"><path d="M11.8,6C9.9,6,8,7.3,7,8.6L6,6H5v6h6v-1l-2.5-1c0.7-1,2.2-2,3.5-2c2.2,0,4,1.9,4,4.1c0,2.2-1.8,4-4,4c-1.1,0-2.1-0.5-2.8-1.2l-1.4,1.4C8.8,17.3,10.3,18,12,18c3.3,0,5.9-2.7,5.9-6S15.1,6,11.8,6z" /></svg></button>
                <button onClick={() => { //redo
                    if (unhistory.length > 0) {
                        blockH.current = true;
                        setStory(unhistory.pop());
                        setUnhistory(unhistory.slice());
                    }
                }} disabled={unhistory.length <= 0}><svg x="0px" y="0px" viewBox="0 0 24 24"><path d="M12.2,6C14.1,6,16,7.3,17,8.6L18,6h1v6h-6v-1l2.5-1c-0.7-1-2.2-2-3.5-2c-2.2,0-4,1.9-4,4.1c0,2.2,1.8,4,4,4c1.1,0,2.1-0.5,2.8-1.2l1.4,1.4C15.2,17.3,13.7,18,12,18c-3.3,0-5.9-2.7-5.9-6S8.9,6,12.2,6z"/></svg></button>
            </div>

            <div className="buttonGroup">
                <button onClick={() => { // italic
                    let s = getSel();
                    story = insert(story, '</i>', s[1]);
                    story = insert(story, '<i>', s[0]);
                    speedy.current = true;
                    setStory(story);
                }}><svg x="0px" y="0px" viewBox="0 0 24 24" ><polygon id="XMLID_5_" points="16.1,8 16.7,6 10.7,6 10.1,8 12.1,8 9.9,16 7.9,16 7.3,18 13.3,18 13.9,16 11.9,16 14.1,8 "/></svg></button>
                <button className="wobble" onClick={() => { // wobble
                    let s = getSel()[0];
                    story = insert(story, '\n<img class="ps"/>\n', s);
                    speedy.current = true;
                    setStory(story);
                }}><svg x="0px" y="0px" viewBox="0 0 36 24"><path id="XMLID_21_" d="M26.1,8.5l0.2,1c6.7,0.4,5.8,4.3,4.5,5c-1,0.5-3,1.1-6.9-0.7c-0.8-0.4-1.6-0.7-2.3-1.1l2.1-1.8l-1-1.1l-2.6,2.2c-1.1-0.6-1.9-1-2.5-1.3l2-1.8l-1-1.1l-2.5,2.2c-6-3-12.5-3-13.4,1.2c-0.9,4.4,4.7,5.6,7.2,4.4l-0.2-1c-6.7-0.4-5.8-4.3-4.5-5c1-0.5,3-1.1,6.9,0.7c0.8,0.4,1.6,0.7,2.3,1.1l-2.1,1.8l1,1.1l2.6-2.2c1.1,0.6,1.9,1,2.5,1.3l-2,1.8l1,1.1l2.5-2.2c6,3,12.5,3,13.4-1.2C34.1,8.5,28.5,7.3,26.1,8.5z"/></svg></button>
                <button onClick={() => { // bold
                    let s = getSel();
                    story = insert(story, '</b>', s[1]);
                    story = insert(story, '<b>', s[0]);
                    speedy.current = true;
                    setStory(story);
                }}><svg x="0px" y="0px" viewBox="0 0 24 24"><path d="M15,12c2.8-0.5,3-6-1.5-6h-2.2h-3H7v2h1v8H7v2h1.3h3h2.2C18.1,18,17.7,12.6,15,12z M13,16h-2v-3h2C15,13,15,16,13,16z M13,11h-2V8h2C15,8,15,11,13,11z"/></svg></button>
            </div>

            <div className="buttonGroup">
                <button onClick={() => { // save
                    if (validate()) {
                        setSaveModal(true)
                    } else {
                        setValidateModal(true);
                    }
                }}><svg x="0px" y="0px" viewBox="0 0 24 24"><path d="M15.6,5H6.9C5.8,5,5,5.8,5,6.9v10.3c0,1,0.8,1.9,1.9,1.9h10.3c1,0,1.9-0.8,1.9-1.9V8.4L15.6,5z M8,7.8C8,7.4,8.4,7,8.8,7h6.3C15.6,7,16,7.4,16,7.8v1.3c0,0.5-0.4,0.8-0.8,0.8H8.8C8.4,10,8,9.6,8,9.2V7.8z M17,15.8c0,0.7-0.5,1.2-1.2,1.2H8.2C7.5,17,7,16.5,7,15.8v-2.6C7,12.5,7.5,12,8.2,12h7.6c0.7,0,1.2,0.5,1.2,1.2V15.8z"/><rect x="13" y="7.5" width="2" height="2"/></svg></button>
                <button onClick={() => { // close
                    setCloseModal(true);
                }}><svg x="0px" y="0px" viewBox="0 0 24 24" ><polygon points="17.7,16.3 13.4,12 17.4,8.1 16,6.6 12,10.6 7.7,6.3 6.3,7.7 10.6,12 6.6,16 8.1,17.4 12,13.4 16.3,17.7 "/></svg></button>
            </div>
        </div>
        <div className="title"><h4>{title}</h4></div>
        <div className="HIDDEN">
            <div className="fakeTextArea" ref={ref}><pre>{story}</pre></div>
        </div>
        <textarea ref={textarea} spellCheck="false" value={story} style={{ height:height+16*2 }} onChange={e=>setStory(e.target.value)} />
    </div>
}

function Fruit({ b, j, flipped, onMobile, scroll, window }) {
    let [hovered, setHovered] = useState(false);
    let ref = useRef();
    let [opacity, setOpacity] = useState(onMobile?0:1);
    useEffect(() => {
        if (onMobile) {
            let rect = window.current.getBoundingClientRect();
            let height = rect.height
            let d = ref.current.getBoundingClientRect().top - rect.top;
            if (d < rect.height / 3) {
                setOpacity(d / (height / 3));
            } else if (d > (height / 3) * 2) {
                d -= (height / 3) * 2;
                setOpacity(1 - d / (height / 3));
            } else setOpacity(1);
        }
    },[scroll])
    return <div
        className={`book b${j} ${flipped ? 'flipped':''}`}
    >
        <div className={`title ${hovered || onMobile?'':'invisible'}`}>
            <div className="label" ref={ref} style={onMobile?{opacity}:null}>
                {b.title}
            </div>
        </div>
        <NavLink to={'/'+encodeURIComponent(url(b.title))}><img
            alt={b.title}
            className="book"
            src={imgBook}
            onMouseEnter={()=>setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        /></NavLink>
    </div>
}

function Book({ b, showCount }) {
    let [title, setTitle] = useState('');
    let [html, setHtml] = useState('');
    let [count, setCount] = useState();
    useEffect(() => {
        if (b) {
            setTitle(b[0]);
            setHtml(b[1]);
            ref.current.scrollTo(0, 0);
            (async () => {
                setCount();
                let { data } = await axios.post('https://kaisboatfund.co.uk/api/p/view', { title: b[0], p:p() });
                if (data.count||data.count===0) setCount(data.count);
            })()
        }
    }, [b])
    let ref = useRef();
    return <div className={`fullScreen ${b ? '' : 'invisible'}`} ref={ref}>
        <NavLink to='/'><div className="X">
            X
        </div></NavLink>
        <div className="Book" >
            <h1>{title}</h1>
            {showCount && (count || count === 0) ? <h2 className="count">Views: {count}</h2> : null}
            <div className="html" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    </div>
}