import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import "./App.css";
import axios from 'axios';
import Tree from './c/Tree';
import imgConfidential from './p/confidential.png';

export default function App({ }) {
return <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<App1/>}>
                <Route path=":route" element={<App1 />} />
            </Route>
        </Routes>
    </BrowserRouter>
}

function Char({children, onClick}) {
    let [offset, setOffset] = useState(0);
    let timeout = useRef();
    useEffect(() => {
        if (offset) {
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => setOffset(0), 100);
        }
    },[offset])
    return <span onClick={() => {
        if (children.trim()) {
            setOffset(12);
            onClick();
        }
    }} style={{ top: -offset }}>{children}</span>
}

function Title({ setCounts, setPass }) {
    let [password, setPassword] = useState('');
    let title = 'P Douglas Hammond'.split('');
    useEffect(() => {
        (async () => {
            if (password.length >= 5) {
                let { data } = await axios.post('https://kaisboatfund.co.uk/api/p/hello', { password });
                if (!data.go) {
                    setPass(password);
                    setPassword('');
                    setCounts(data);
                    localStorage.setItem('daddy', data.p);
                }
            }
        })()
    },[password])
    return <h1 className="name">{title.map((l, i) => <Char key={i} onClick={()=>setPassword(password+l)}>{l}</Char>)}</h1>
}

function Confidential({ }) {
    let [visible, setVisible] = useState(false);
    return <img className={`Confidential ${visible?'':'invisible'}`} src={imgConfidential} alt="confidential" onLoad={()=>setVisible(true)}/>
}

function p() {
    return localStorage.getItem('daddy')?localStorage.getItem('daddy') : null;
}

function App1({}) {
    let [fruit, setFruit] = useState()
    let [story, setStory] = useState()
    let [showTitles, setShowTitles] = useState(true);
    let [tab, setTab] = useState('Tree');
    let params = useParams();
    let { route } = params;
    let [password, setPassword] = useState();

    let [counts, setCounts] = useState();

    let [size, setSize] = useState('BIG')
    let navigate = useNavigate();

    useEffect(() => {
        (async () => {
            let { data } = await axios.post('https://kaisboatfund.co.uk/api/p/titles',{p:p()});
            setFruit(data.fruit);
            setStory(data.story);
        })()
    }, [])
    
    useEffect(() => {
        if (route) route = route.toLowerCase();
        if (route == 'biography') {
            setTab('Biography')
        } else if (route == 'contact') {
            setTab('Contact')
        } else if (route == 'analytics') {
            if (counts) {
                setTab('Analytics')
            } else {
                navigate('/')
            }
        } else if (route == 'edit') {
            if (counts) {
                setTab('Edit')
            } else {
                navigate('/')
            }
        } else if (route == 'newsletter') {
            if (counts) {
                setTab('Newsletter')
            } else {
                navigate('/')
            }
        } else {
            setTab('Tree');
        }
    },[route])
    
    useEffect(() => {
        let f = () => {
            let w = window.innerWidth;
            let h = window.innerHeight;
            setShowTitles((h>800&&w>700)||(h>620&&w<=700&&w>550)||(h>520&&w<=550))
            setSize(w<550||h<540?'SMALL':w<700||h<700?'MEDIUM':'BIG');
        }
        f();
        window.addEventListener('resize', f);
        return () => window.removeEventListener('resize', f);
    },[])
    
    return <div className={`App ${size} ${showTitles ? '' : 'small'}`}>
        {counts?<Confidential/>:null}
        <div className="centre" style={{opacity:fruit?1:0}}>
            <Title setCounts={setCounts} setPass={setPassword} />
            {showTitles?<h2 className="SoppilyRomanticTheologicalScienceFilosophy">SoppilyRomanticTheologicalScienceFilosophy</h2>:null}
            {fruit ? <Tree
                fruit={fruit}
                story={story}
                setFruit={setFruit}
                setStory={setStory}
                tab={tab}
                route={route}
                counts={counts}
                password={password}
            /> : null}
            <div className="buttons buttonsUnderTheSquareBit">
                <NavLink to="/" className={tab=='Tree'?'selected':null}>Tree</NavLink>
                <NavLink to="/biography" className={tab=='Biography'?'selected':null}>Biography</NavLink>
                <NavLink to="/contact" className={tab == 'Contact' ? 'selected' : null}>Contact</NavLink>
                {counts ? <>
                    <NavLink to="/analytics" className={tab == 'Analytics' ? 'a selected' : 'a'}>Analytics</NavLink>
                    <NavLink to="/newsletter" className={tab == 'Newsletter' ? 'a selected' : 'a'}>Newsletter</NavLink>
                    <NavLink to="/edit" className={tab == 'Edit' ? 'a selected' : 'a'}>Edit Stories</NavLink>
                </> : null}
            </div>
        </div>
    </div>
}